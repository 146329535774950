<script setup lang="ts">
import { ArrowSmRightIcon, FireIcon, SparklesIcon, SwitchHorizontalIcon } from '@heroicons/vue/solid';
import { useInterval } from '@vueuse/core';
import { differenceInMilliseconds, intlFormat, parseISO } from 'date-fns';
import humanizeDuration from 'humanize-duration';
import type { PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import Popper from 'vue3-popper';
import { formatXchPrice } from '~/utilities/chia-display-utils';
import { getUrlForCollection, getUrlForNft, getUrlForProfile } from '~/utilities/url-slugs';

const { locale } = useI18n();

const props = defineProps({
  events: { type: Object as PropType<Array<any>>, required: true },
  showSender: { type: Boolean, default: false },
});

const { events, showSender } = toRefs(props);

const isSensitiveContent = (event) =>
  event.collection?.sensitive_content ||
  event.nft.data.sensitive_content === true ||
  event.nft.data.sensitive_content === 'true';

const mapEvent = (event) => {
  switch (event.type) {
    case 0:
      return { content: 'activity.minted', icon: SparklesIcon };
    case 1:
      return { content: 'activity.transferred', icon: ArrowSmRightIcon };
    case 2:
      return {
        content: `activity.traded`,
        mobileContent: `Traded for ${formatXchPrice(event.xch_price)}`,
        icon: SwitchHorizontalIcon,
        background: 'bg-black dark:bg-white',
      };
    case 3:
      return { content: 'activity.burned', icon: FireIcon };
  }
};

const formatPayments = (event: any) => {
  return Object.entries(
    (event.payments || []).reduce(
      (acc: any, payment: any) => ({
        ...acc,
        [payment.asset_id || 'xch']: (acc[payment.asset_id || 'xch'] || 0) + payment.amount,
      }),
      {}
    )
  )
    .map(([assetId, amount]) => ({
      assetId,
      amount,
    }))
    .sort(({ assetId: a }) => (a === 'xch' ? -1 : 1));
};

const formatDuration = (timestamp: string) => {
  return humanizeDuration(differenceInMilliseconds(parseISO(timestamp), new Date()), {
    largest: 1,
    maxDecimalPoints: 0,
    language: locale.value.replace('zh', 'zh_CN') || 'en',
  });
};
const formatTimestamp = (timestamp: string) => {
  return intlFormat(parseISO(timestamp), { dateStyle: 'medium', timeStyle: 'medium' } as any);
};

const durations = ref({});
const interval = useInterval(60000);
watch([events, interval], () => {
  durations.value = events.value.reduce((acc, event) => {
    return { ...acc, [event.id]: formatDuration(event.timestamp) };
  }, {});
});
</script>
<template>
  <div>
    <div
      class="-mx-4 mt-1 bg-white ring-neutral-300 dark:bg-black dark:ring-neutral-600 sm:-mx-4 md:mx-0 md:rounded-lg md:ring-1"
    >
      <table class="min-w-full divide-neutral-300 dark:divide-neutral-600 md:divide-y">
        <thead class="hidden md:table-header-group">
          <tr>
            <th class="hidden w-40 px-3 py-3.5 md:table-cell"></th>

            <EventsTableHeader hide-below="sm">{{ $t('activity.asset') }}</EventsTableHeader>
            <EventsTableHeader hide-below="md" align="right">{{ $t('activity.price') }}</EventsTableHeader>
            <EventsTableHeader v-if="showSender" hide-below="md" align="center"
              >{{ $t('activity.sender') }}
            </EventsTableHeader>
            <EventsTableHeader hide-below="md" align="center">{{ $t('activity.recipient') }}</EventsTableHeader>
            <EventsTableHeader hide-below="md" align="center">{{ $t('activity.time') }}</EventsTableHeader>
          </tr>
        </thead>
        <tbody>
          <NuxtLink
            v-for="(event, planIdx) in events"
            :key="event.id"
            v-slot="{ navigate }"
            :to="getUrlForNft(event.nft)"
            custom
          >
            <tr class="cursor-pointer hover:bg-neutral-50 dark:hover:bg-neutral-900" @click="navigate">
              <td
                :class="[
                  planIdx === 0 ? '' : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden px-3 py-3.5 font-medium text-neutral-900 dark:text-neutral-50 md:table-cell',
                ]"
              >
                <div class="flex items-center text-sm">
                  <span :class="['flex h-8 w-8 items-center justify-center']">
                    <component
                      :is="mapEvent(event).icon"
                      class="h-6 w-6 text-black dark:text-white"
                      aria-hidden="true"
                    />
                  </span>
                  {{ $t(mapEvent(event).content) }}
                </div>
              </td>
              <td
                :class="[
                  planIdx === 0 ? '' : 'border-neutral-200 dark:border-neutral-700 md:border-t',
                  'relative px-3 py-6 text-sm',
                ]"
              >
                <div class="flex items-center gap-2">
                  <div class="h-12 w-12 overflow-hidden rounded-md">
                    <Popper hover class="image" open-delay="0" close-delay="0">
                      <img
                        v-if="event.nft.data.thumbnail_uri"
                        :src="event.nft.data.thumbnail_uri"
                        loading="lazy"
                        :class="[isSensitiveContent(event) ? 'blur-md' : '']"
                        class="h-full w-full rounded-md object-cover object-center"
                      />
                      <template #content>
                        <div>
                          <img
                            v-if="event.nft.data.thumbnail_uri"
                            :src="event.nft.data.thumbnail_uri"
                            loading="lazy"
                            :class="[isSensitiveContent(event) ? 'blur-md' : '']"
                            class="h-full w-full rounded-md object-cover object-center"
                          />
                        </div>
                      </template>
                    </Popper>
                  </div>

                  <div class="flex flex-1 flex-col gap-1">
                    <div class="flex justify-between">
                      <NuxtLink
                        v-if="event.collection"
                        :to="getUrlForCollection(event.collection)"
                        class="flex items-center gap-1"
                      >
                        <span
                          class="text-md max-w-[15rem] truncate text-neutral-600 hover:text-neutral-900 dark:text-neutral-300 dark:text-neutral-50"
                          >{{ event.collection.name }}</span
                        >
                      </NuxtLink>
                      <NuxtLink
                        v-else-if="event.creator"
                        :to="getUrlForProfile(event.creator)"
                        class="flex items-center gap-1"
                      >
                        <span
                          class="text-md max-w-[15rem] truncate text-neutral-600 hover:text-neutral-900 dark:text-neutral-300 dark:text-neutral-50"
                          >{{ event.creator.name }}</span
                        >
                      </NuxtLink>
                      <div class="flex flex-col text-neutral-600 dark:text-neutral-300 md:hidden">
                        {{ $t(mapEvent(event).content) }}
                      </div>
                    </div>
                    <div class="flex justify-between gap-2">
                      <div
                        class="text-md max-w-[15rem] flex-grow-0 truncate font-bold text-neutral-900 dark:text-neutral-50"
                      >
                        {{ event.nft.data.name || 'Unnamed' }}
                      </div>
                      <div class="flex-shrink-0 md:hidden">
                        <div
                          v-if="event.xch_price || event.payments?.length > 0"
                          class="inline-flex flex-col items-end gap-1"
                        >
                          <span
                            v-for="payment in formatPayments(event)"
                            :key="payment.assetId"
                            class="inline-flex items-center"
                          >
                            <NuxtLink
                              v-if="payment.assetId !== 'xch'"
                              target="_blank"
                              :to="`https://dexie.space/assets/${payment.assetId}`"
                              class="inline-flex items-center gap-1 font-semibold text-neutral-700 dark:text-neutral-200"
                            >
                              <img
                                class="h-4 w-4 rounded-full"
                                :src="`https://icons.dexie.space/${payment.assetId}.webp`"
                              />{{ payment.amount / 1000 }}
                            </NuxtLink>
                            <span
                              v-else
                              class="inline-flex items-center gap-1 font-semibold text-neutral-900 dark:text-neutral-50"
                            >
                              <img class="h-4 w-4" src="https://assets.mainnet.mintgarden.io/web/chia_circle.png" />
                              <Popper
                                class="flex-shrink-0"
                                :content="(payment.amount / 10 ** 12).toString()"
                                arrow
                                hover
                                >{{ (payment.amount / 10 ** 12).toFixed(2) }}</Popper
                              >
                            </span>
                          </span>
                        </div>
                        <div v-else-if="event.type === 2" class="text-end">---</div>
                        <div v-else>
                          <ProfileHandle
                            v-if="event.owner"
                            :profile="event.owner"
                            avatar-dimension="1rem"
                            named-classes="font-bold text-neutral-700 dark:text-neutral-200 text-sm max-w-[6rem] truncate"
                            unnamed-classes="tracking-tight font-mono text-neutral-900 dark:text-neutral-50 text-sm max-w-[6rem] truncate"
                          />
                          <AddressHandle
                            v-else
                            :address="event.address"
                            avatar-dimension="1rem"
                            named-classes="font-bold text-neutral-700 dark:text-neutral-200 text-sm max-w-[6rem] truncate"
                            unnamed-classes="tracking-tight font-mono text-neutral-900 dark:text-neutral-50 text-sm max-w-[6rem] truncate"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="text-end text-sm text-neutral-600 dark:text-neutral-300 md:hidden">
                      <Popper :content="formatTimestamp(event.timestamp)" hover arrow>
                        {{ formatDuration(event.timestamp) + $t('activity.ago') }}
                      </Popper>
                    </div>
                  </div>
                </div>
              </td>
              <td
                :class="[
                  planIdx === 0 ? '' : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden px-3 py-3.5 text-sm font-semibold text-neutral-900 dark:text-neutral-50 md:table-cell',
                ]"
              >
                <div v-if="event.xch_price || event.payments?.length > 0" class="flex flex-col items-end gap-1">
                  <span
                    v-for="payment in formatPayments(event)"
                    :key="payment.assetId"
                    class="inline-flex items-center"
                  >
                    <NuxtLink
                      v-if="payment.assetId !== 'xch'"
                      target="_blank"
                      :to="`https://dexie.space/assets/${payment.assetId}`"
                      class="inline-flex items-center gap-1 font-semibold text-neutral-700 dark:text-neutral-200"
                    >
                      <img class="h-6 w-6 rounded-full" :src="`https://icons.dexie.space/${payment.assetId}.webp`" />{{
                        payment.amount / 1000
                      }}
                    </NuxtLink>
                    <span
                      v-else
                      class="inline-flex items-center gap-1 font-semibold text-neutral-900 dark:text-neutral-50"
                    >
                      <img class="h-6 w-6" src="https://assets.mainnet.mintgarden.io/web/chia_circle.png" />
                      <Popper class="flex-shrink-0" :content="(payment.amount / 10 ** 12).toString()" arrow hover>{{
                        (payment.amount / 10 ** 12).toFixed(2)
                      }}</Popper>
                    </span>
                  </span>
                </div>
                <div v-else-if="event.type === 2" class="text-end">---</div>
              </td>
              <td
                v-if="showSender"
                :class="[
                  planIdx === 0 ? '' : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden max-w-[150px] truncate px-3 py-3.5 text-center text-sm font-medium text-neutral-900 dark:text-neutral-50 md:table-cell',
                ]"
              >
                <ProfileHandle v-if="event.previous_owner" :profile="event.previous_owner" />
                <AddressHandle v-else :address="event.previous_address" />
              </td>
              <td
                :class="[
                  planIdx === 0 ? '' : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden max-w-[150px] truncate px-3 py-3.5 text-center text-sm font-medium text-neutral-900 dark:text-neutral-50 md:table-cell',
                ]"
              >
                <ProfileHandle v-if="event.owner" :profile="event.owner" />
                <AddressHandle v-else :address="event.address" />
              </td>
              <td
                :class="[
                  planIdx === 0 ? '' : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden px-3 py-3.5 text-center text-sm font-medium text-neutral-900 dark:text-neutral-50 md:table-cell',
                ]"
              >
                <Popper :content="formatTimestamp(event.timestamp)" hover arrow>
                  {{ durations[event.id] + $t('activity.ago') }}
                </Popper>
              </td>
            </tr>
          </NuxtLink>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
:deep(.popper) {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 12px;
  --popper-theme-padding: 8px 12px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

:deep(.image .popper) {
  --popper-theme-padding: 8px;
}
</style>
