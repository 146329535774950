<script setup lang="ts">
import type { PropType } from 'vue';

const props = defineProps({
  hideBelow: String as PropType<'sm' | 'md' | 'lg'>,
  hideAbove: String as PropType<'sm' | 'md' | 'lg'>,
  align: String as PropType<'start' | 'center' | 'end' | undefined>,
});
const { hideBelow, align } = toRefs(props);
</script>

<template>
  <th
    :class="[
      'px-3 py-3.5 text-sm font-semibold text-neutral-500 dark:text-neutral-400',
      hideBelow ? hideBelow + ':table-cell hidden' : '',
      align ? `text-${align}` : 'text-start',
    ]"
  >
    <div class="group inline-flex">
      <slot></slot>
    </div>
  </th>
</template>
